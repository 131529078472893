import { useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      <footer
        className={`${
          pathname === "/about-us" ||
          pathname === "/contact-us" ||
          pathname === "/coming-soon"
            ? "hidden sm:block"
            : "block"
        } bg-skin relative`}
      >
        <div className="max-w-[662px] px-4 mx-auto py-5 sm:pt-12">
          <div className="flex items-center justify-between flex-col sm:flex-row sm:py-12 px-8 sm:border-t border-primary">
            <div>
              <span className="text-xs sm:text-[10px] hover:underline font-inter-light tracking-[2px]">
                <a href={"mailto:team@envvia.com"}>EMAIL: TEAM@ENVVIA.COM</a>
              </span>
            </div>
            <div>
              <span className="text-xs sm:text-[10px] font-inter-light tracking-[2px]">
                LAS VEGAS | HONG KONG
              </span>
            </div>
          </div>
        </div>
      </footer>
      <div className="py-3 bg-[#DDD9D4]">
        <p className="text-center text-[10px] sm:text-sm font-inter-regular tracking-[3.75px] text-[#7E7C7C] ">
          ENVVIA 2024
        </p>
      </div>
    </>
  );
}
