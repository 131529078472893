import { Link } from "react-router-dom";
import { Container } from "../Container";
import { Menu } from "../../assets/icons";
import Sidebar from "../Sidebar";
import { useState } from "react";
import { scrollToTop } from "../../utils";
import { images } from "../../assets/images";

const LeftNavigation = [
  {
    name: "PRODUCT LISTINGS",
    href: "https://showroom.envvia.com",
  },
  {
    name: "PARTNERSHIP PROGRAM",
    href: "/#partner",
  },
];

const RightNavigation = [
  {
    name: "ABOUT ENVVIA",
    href: "/about-us",
  },
  {
    name: "CONTACT US",
    href: "/contact-us",
  },
];

const Header = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <>
      <div className="h-3.5 sm:h-6 bg-skin"></div>
      <nav className="w-full bg-primary z-30 shadow sticky top-0 p-3">
        <Container size="md">
          <div
            className="flex items-center justify-center relative lg:justify-between py-3 sm:py-5"
            onClick={() => setSidebarOpen(true)}
          >
            <div className="lg:hidden absolute left-0">
              <Menu />
            </div>
            <ul className="items-center justify-center space-x-6 hidden lg:flex relative z-20">
              {LeftNavigation?.map((item, i) => {
                if (i === 0) {
                  return (
                    <Link to={item.href} key={i}>
                      <li className="mb-5 text-secondary text-sm hover:underline font-crimson-regular md:mb-0">
                        {item.name}
                      </li>
                    </Link>
                  );
                }

                if (i !== 0) {
                  return (
                    <a href="/#partner" key={i}>
                      <li className="mb-5 text-secondary text-sm hover:underline font-crimson-regular md:mb-0">
                        {item.name}
                      </li>
                    </a>
                  );
                }
              })}
            </ul>
            <Link
              to="/"
              onClick={scrollToTop}
              className={"absolute w-full flex justify-center"}
            >
              <img src={images.logo} alt="Envvia Logo" width={100} />
            </Link>
            <ul className="items-center justify-center space-x-6 hidden lg:flex relative z-20">
              {RightNavigation?.map((item, i) => (
                <Link to={item.href} key={i}>
                  <li className="mb-5 text-secondary text-sm hover:underline font-crimson-regular md:mb-0">
                    {i === 0 ? (
                      <>
                        ABOUT<span className="italic"> ENVVIA</span>
                      </>
                    ) : (
                      item.name
                    )}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        </Container>
      </nav>

      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    </>
  );
};
export default Header;
