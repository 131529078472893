import banner from "./banner.png";
import partner_1 from "./partner-1.jpg";
import partner_2 from "./partner-2.jpg";
import treasure_1 from "./treasure-1.jpg";
import treasure_2 from "./treasure-2.jpg";
import treasure_3 from "./treasure-3.jpg";
import treasure_4 from "./treasure-4.jpg";
import network from "./network.png";
import contact from "./contact.png";
import about from "./about.png";
import coming from "./coming-soon.png";
import linkPreview from "./link.jpeg";
import logo from "./envvia-logo.png";

export const images = {
  banner,
  partner_1,
  partner_2,
  treasure_1,
  treasure_2,
  treasure_3,
  treasure_4,
  network,
  contact,
  about,
  coming,
  linkPreview,
  logo,
};
