import { Container } from "../../../components/Container";

const BannersSection = () => {
  return (
    <>
      <Container
        size="md"
        className="pb-12 pt-7 !px-6 sm:py-[3.75rem] contact-container"
      >
        <div className="!max-w-[700px] m-auto">
          <h1 className="text-xl sm:text-3xl font-crimson-semibold mb-6 text-center pt-10">
            Get in Touch.
          </h1>
          <p className="text-base sm:text-xl font-crimson-regular max-w-[500px] m-auto">
            Whether you're a Jeweler, Designer or Brand interested in joining
            our network or Jewelry Consultant craving a more personalized,
            digital shopping experience for your clients, Envvia is the ultimate
            spot for a good time. We're all about making luxury jewelry shopping
            a blast—blending digital delights, human connections, and unique
            finds. <br />
            <br />
            If you wish to speak to our concierge or apply as Partner/Private
            Jewelry Consultant, simply drop us a line at{" "}
            <a href={"mailto:team@envvia.com"} className={"underline"}>
              team@envvia.com
            </a>{" "}
            now and let's turn this into a rewarding journey you'll love❤️
          </p>
        </div>
      </Container>
      {/*<div className="flex w-full justify-center border-t border-[#8E8D8D] relative mb-10 sm:mb-20">
        <div className="absolute top-1/2 -translate-y-1/2">
          <h2 className="text-lg sm:text-xl lg:text-2xl px-6 bg-white whitespace-nowrap italic font-crimson-regular">
            Please fill the form below
          </h2>
        </div>
      </div>*/}
    </>
  );
};

export default BannersSection;
