interface IProps {
  className?: string;
}

const Arrow = ({ className }: IProps) => {
  return (
    <svg
      width="22"
      height="11"
      className={`${className ? className : ""}`}
      viewBox="0 0 22 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M21 10.0726L11 1L1 10.0726" fill="#FDFDFD" />
      <path
        d="M21 10.0726L11 1L1 10.0726"
        stroke="#545454"
        strokeWidth="0.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Menu = ({ className }: IProps) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      className={`${className ? className : ""}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.5827 11.5938H6.41724C6.3012 11.5938 6.18992 11.5477 6.10788 11.4656C6.02583 11.3836 5.97974 11.2723 5.97974 11.1562C5.97974 11.0402 6.02583 10.9289 6.10788 10.8469C6.18992 10.7648 6.3012 10.7188 6.41724 10.7188H28.5827C28.6988 10.7188 28.81 10.7648 28.8921 10.8469C28.9741 10.9289 29.0202 11.0402 29.0202 11.1562C29.0202 11.2723 28.9741 11.3836 28.8921 11.4656C28.81 11.5477 28.6988 11.5938 28.5827 11.5938ZM28.5827 17.9375H6.41724C6.3012 17.9375 6.18992 17.8914 6.10788 17.8094C6.02583 17.7273 5.97974 17.616 5.97974 17.5C5.97974 17.384 6.02583 17.2727 6.10788 17.1906C6.18992 17.1086 6.3012 17.0625 6.41724 17.0625H28.5827C28.6988 17.0625 28.81 17.1086 28.8921 17.1906C28.9741 17.2727 29.0202 17.384 29.0202 17.5C29.0202 17.616 28.9741 17.7273 28.8921 17.8094C28.81 17.8914 28.6988 17.9375 28.5827 17.9375ZM28.5827 24.2812H6.41724C6.3012 24.2812 6.18992 24.2352 6.10788 24.1531C6.02583 24.0711 5.97974 23.9598 5.97974 23.8438C5.97974 23.7277 6.02583 23.6164 6.10788 23.5344C6.18992 23.4523 6.3012 23.4062 6.41724 23.4062H28.5827C28.6988 23.4062 28.81 23.4523 28.8921 23.5344C28.9741 23.6164 29.0202 23.7277 29.0202 23.8438C29.0202 23.9598 28.9741 24.0711 28.8921 24.1531C28.81 24.2352 28.6988 24.2812 28.5827 24.2812Z"
        fill="white"
      />
    </svg>
  );
};
const Cross = ({ className }: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      className={`${className ? className : ""}`}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M20 1.75L18.25 0L10 8.25L1.75 0L0 1.75L8.25 10L0 18.25L1.75 20L10 11.75L18.25 20L20 18.25L11.75 10L20 1.75Z"
        fill="black"
      />
    </svg>
  );
};

export { Arrow, Menu, Cross };
