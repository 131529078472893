import { Container } from "../../../components/Container";

const EnvviaSection = () => {
  return (
    <div className="py-[65px] bg-skin">
      <Container className="!max-w-[533px]">
        <h3 className="text-left sm:text-center text-3xl mb-4 font-cormorant-semibold">
          Who are we?
        </h3>
        <p className="text-left sm:text-center text-base font-crimson-regular pb-5">
          Envvia - A New Personal Jewelry Shopping Destination. Our mission is
          to transform the world of fine and high jewelry shopping into a truly
          personalized and extraordinary experience. With our network of
          seasoned and knowledgeable Jewelry Consultants just a tap away,
          accessible through instant messaging and social media, we offer expert
          advice on jewelry collecting, styling ideas, product recommendations,
          and inspiring content showcasing top jewelers and designs. No request
          is too extravagant, no detail too minute, and we're here to make it
          happen.
        </p>
      </Container>
    </div>
  );
};

export default EnvviaSection;
